import * as React from 'react'
import { motion } from 'framer-motion'

import * as styles from './style.module.css'

export const MenuToggle = ({ toggle }) => (
  <button className={styles.button} onClick={toggle}>
    <svg
      style={{ height: '2.5rem', width: '2.5rem', fill: '#0b1e31' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.25 6.24"
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M0 0H24.25V2.08H0z" className="cls-1"></path>
          <path d="M0 4.16H24.25V6.24H0z" className="cls-1"></path>
        </g>
      </g>
    </svg>
  </button>
)

const Path = props => (
  <motion.path fill="transparent" strokeWidth="2" stroke="black" strokeLinecap="round" {...props} />
)
