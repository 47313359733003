import React from 'react'
import { ScrollProvider } from './src/context/scrollProvider'

export const wrapRootElement = ({ element }) => <ScrollProvider>{element}</ScrollProvider>

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    // if filtering collections don't scroll to top
    if (location.state.shouldUpdateScroll === false) {
      return true
    }
    // old regex test:
    // if (/collections\/[a-z0-9_.-]+\/[a-z0-9_.-]+/.test(location.pathname)) {
    //   return false;
    // }

    window.scrollTo(0, 0)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.scrollTo(0, 0)
  }
  return false
}
