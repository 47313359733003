import React from 'react'
import { Link } from 'gatsby'

export const PageLink = (props: {
  className?: string
  to: string
  scrollPosition?: number
  activeClass?: string
  previousLink?: object
  type?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseOver?: () => void
  children?: any
  style?: object
}) => (
  <Link
    style={{ ...props.style }}
    type={props.type}
    state={{
      previous: { url: props.previousLink ? props.previousLink : '/' },
      scrollPosition: props.scrollPosition ? props.scrollPosition : false,
    }}
    className={props.className}
    activeClassName={`${props.activeClass ? props.activeClass : 'active-link'}`}
    onClick={props.onClick}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onMouseOver={props.onMouseOver}
    to={props.to}
  >
    {props.children}
  </Link>
)
