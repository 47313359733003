import React from 'react'
import { useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import {} from '../svgs'
import { MenuToggle } from './menuToggle'
import { Navigation } from './navigation'
import * as styles from './style.module.css'

const backgroundVariants = {
  initial: {
    display: 'none',
    opacity: 0,
    y: 200,
  },
  open: {
    opacity: 1,
    animate: { scale: 2 },
    display: 'block',
  },
  closed: {
    animate: { opacity: 0 },
    display: 'none',
    transition: {
      delay: 1,
    },
  },
  exit: { opacity: 0 },
}

export const MobileMenuContainer = ({ mobileNavMenu, footerMenu, pathName }: any) => {
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    function disableScrolling() {
      const x = window.scrollX
      const y = window.scrollY
      window.onscroll = function () {
        window.scrollTo(x, y)
      }
    }

    function enableScrolling() {
      window.onscroll = function () {}
    }

    if (isOpen) {
      disableScrolling()
    } else {
      enableScrolling()
      return () => window.removeEventListener('onscroll', disableScrolling)
    }
  }, [isOpen])

  return (
    <motion.nav
      className={styles.nav}
      ref={containerRef}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
    >
      <motion.div
        style={{ backgroundColor: isOpen ? '#f5f5f7' : null }}
        className={styles.background}
        variants={backgroundVariants}
      />
      <Navigation
        pathName={pathName}
        footerMenu={footerMenu}
        mobileNavMenu={mobileNavMenu && mobileNavMenu}
        innerToggle={() => setOpen(!isOpen)}
        toggle={() => setOpen(!isOpen)}
      />
      <MenuToggle toggle={() => setOpen(!isOpen)} />
    </motion.nav>
  )
}

//
