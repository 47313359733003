import React, { useEffect, useState } from 'react'

import { MainSiteLogo } from './svgs'

import { MobileMenuContainer } from './mobileBurgerMenu/mobileMenucontainer'
import { PageLink } from 'src/components/link'
import { navigate } from 'gatsby'
export const Header = ({ menu, location }: any) => {
  const [highLightParentLink, setHighlightParentLink] = useState('')
  const footerMenu =
    menu &&
    menu.data.allSanityMenus.edges.filter(
      x => x.node.slug.current === 'footer-social-media-links'
    )[0].node
  const desktopHeaderMenu =
    menu &&
    menu.data.allSanityMenus.edges.find(x => x.node.slug.current === 'desktop-header-nav').node
      ._rawItems
  const mobileNavMenu =
    menu &&
    menu.data.allSanityMenus.edges.find(x => x.node.slug.current === 'mobile-nav').node._rawItems
  const { pathname } = location
  useEffect(() => {
    setHighlightParentLink(location)
  }, [location])
  return (
    <div>
      <div className="header-container-section">
        <div className="desktop-header-wrapper">
          <ul className="__type-links-container">
            {desktopHeaderMenu &&
              desktopHeaderMenu.map((item, index) => {
                return (
                  <TypeLinkResolver
                    key={index}
                    pathname={pathname}
                    highLightParentLink={highLightParentLink}
                    setHighlightParentLink={e => setHighlightParentLink(e)}
                    item={item}
                  />
                )
              })}
          </ul>
        </div>

        <PageLink activeClass="null" className="--main-site-logo" to="/">
          <MainSiteLogo homePage={true} className="--main-site-logo" />
        </PageLink>

        <a
          target="_blank"
          rel="noopener _custom noreferrer"
          href="https://icx.efrontcloud.com/@370/corsairinvestments/login.aspx"
          className="blue __login-button font-65-medium text-transform-uppercase "
        >
          Investor Login
        </a>
      </div>
      <div className="header-mobile">
        <MainSiteLogo onClick={() => navigate('/')} homePage={false} className="--main-site-logo" />
        <MobileMenuContainer
          pathName={pathname}
          footerMenu={footerMenu}
          mobileNavMenu={mobileNavMenu && mobileNavMenu}
        />
      </div>
    </div>
  )
}

const TypeLinkResolver = ({ item, pathname, setHighlightParentLink }: any) => {
  const { _type } = item
  switch (_type) {
    case 'internalLink':
      return <InternalLink data={item} />
    case 'dropdownLink':
      return (
        <DropwDownLink
          data={item}
          setHighlightParentLink={e => setHighlightParentLink(e)}
          pathname={pathname}
        />
      )
    default:
      return null
  }
}

interface InternalLinkProps {
  data: {
    _key: string
    title: string
    link: {
      content: {
        main: {
          title: string
          slug: {
            current: string
          }
        }
      }
    }
  }
}

const InternalLink = ({ data }: InternalLinkProps) => {
  const { _key } = data
  if (data.link && data.link.content) {
    return (
      <PageLink
        key={`page-link-header-desktop${_key} text-hover active-dropdown-link `}
        className=" text-uppercase  font-65-medium  blue margin-right-2rem decoration-none hover-on-text "
        to={`/${data && data.link.content.main.slug.current}`}
      >
        {' '}
        {data.title && data.title}{' '}
      </PageLink>
    )
  }
  return null
}

interface DropwDownLinkProps {
  data: {
    title: string
    _key: string
    subLinks: Array<{
      content: {
        main: {
          title: string
          slug: {
            current: string
          }
        }
      }
    }>
  }
}

const DropwDownLink = ({ data, pathname, setHighlightParentLink }: DropwDownLinkProps) => {
  const { _key, title, subLinks } = data
  const [isHighlighted, setIsHighlighted] = useState(false)
  useEffect(() => {
    const isParentLinkHighlighted = subLinks.some(
      x => x.content.main.slug.current === pathname.split('/')[1]
    )
    if (isParentLinkHighlighted) {
      setIsHighlighted(true)
    } else {
      setIsHighlighted(false)
    }
  }, [pathname])
  return (
    <div style={{ marginRight: '2rem' }} key={_key} className="dropdown">
      <p
        className={`dropbtn  border-bottom-grey  text-uppercase font-65-medium  blue hover-on-text ${
          isHighlighted ? 'active-link' : null
        }`}
      >
        {title}
      </p>
      <div className="dropdown-content">
        <div className="arrow_box">
          {subLinks &&
            subLinks.map(({ content }, index) => {
              const isLinkActive = pathname === '/' + content.main.slug.current
              return (
                <PageLink
                  key={`sublinks-${index}`}
                  onClick={e => setHighlightParentLink(content.main.slug.current)}
                  activeClass="active-class-white "
                  className={`text-uppercase  font-65-medium fit-content   blue  ${
                    isLinkActive ? ' ' : 'white-hover-on-text '
                  }  `}
                  to={'/' + content.main.slug.current}
                >
                  {' '}
                  {content.main.title}{' '}
                </PageLink>
              )
            })}
        </div>
      </div>
    </div>
  )
}
