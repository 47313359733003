import React, { useState, useContext } from 'react'

const ALL_CONST = {
  label: 'ALL',
  value: 'all',
}

const ScrollContext = React.createContext({
  scrollPosition: undefined,
  setSelectedDrawer: () => null,
  selectedDrawer: undefined,
  setScrollPosition: () => null,
  teamFilterParameters: undefined,
  setTeamFilterParameters: () => null,
  selectedTeamProduct: undefined,
  setTeamSelectedProduct: () => null,
  selectedTeamLocation: undefined,
  setSelectedTeamLocation: () => null,
  selectedTeamRole: undefined,
  setSelectedTeamRole: () => null,
  buyoutsFilterParameters: undefined,
  setBuyoutsFilterParameters: () => null,
  selectedBuyoutsFund: undefined,
  setSelectedBuyoutsFund: () => null,
  selectedBuyoutsStatus: undefined,
  setSelectedBuyoutsStatus: () => null,
})

const ScrollProvider = ({ children }: { children: any }) => {
  const [selectedDrawer, setSelectedDrawer] = useState('')
  const [scrollPosition, setScrollPosition] = useState(undefined)

  const [teamFilterParameters, setTeamFilterParameters] = useState([])
  const [selectedTeamProduct, setTeamSelectedProduct] = useState(ALL_CONST)
  const [selectedTeamLocation, setSelectedTeamLocation] = useState(ALL_CONST)
  const [selectedTeamRole, setSelectedTeamRole] = useState(ALL_CONST)

  /// Buyouts  Filter
  const [buyoutsFilterParameters, setBuyoutsFilterParameters] = useState([])
  const [selectedBuyoutsFund, setSelectedBuyoutsFund] = useState(ALL_CONST)
  const [selectedBuyoutsStatus, setSelectedBuyoutsStatus] = useState(ALL_CONST)

  return (
    <ScrollContext.Provider
      value={{
        selectedDrawer,
        setSelectedDrawer,
        scrollPosition,
        setScrollPosition,
        teamFilterParameters,
        setTeamFilterParameters,
        selectedTeamProduct,
        setTeamSelectedProduct,
        selectedTeamLocation,
        setSelectedTeamLocation,
        selectedTeamRole,
        setSelectedTeamRole,
        buyoutsFilterParameters,
        setBuyoutsFilterParameters,
        selectedBuyoutsFund,
        setSelectedBuyoutsFund,
        selectedBuyoutsStatus,
        setSelectedBuyoutsStatus,
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}

function useScrollData() {
  const { scrollPosition } = useContext(ScrollContext)
  return scrollPosition
}

function useSetScrollPosition() {
  const { setScrollPosition } = useContext(ScrollContext)

  function setScroll(position: string) {
    setScrollPosition(position)
  }

  return setScroll
}

function useSelectedDrawer() {
  const { selectedDrawer } = useContext(ScrollContext)
  return selectedDrawer
}

function useSetSelectedDrawer() {
  const { setSelectedDrawer } = useContext(ScrollContext)
  function setDrawer(id: string) {
    setSelectedDrawer(id)
  }
  return setDrawer
}

function useTeamFilterParametersData() {
  const { teamFilterParameters } = useContext(ScrollContext)
  return teamFilterParameters
}

function useSetTeamFilterParametersData() {
  const { setTeamFilterParameters } = useContext(ScrollContext)
  function setFilterParamter(object: Object) {
    setTeamFilterParameters(object)
  }
  return setFilterParamter
}

function useTeamSelectedTeamProductData() {
  const { selectedTeamProduct } = useContext(ScrollContext)
  return selectedTeamProduct
}

function useSetSelectedTeamProductData() {
  const { setTeamSelectedProduct } = useContext(ScrollContext)
  function selectedTeamProduct(object: any) {
    setTeamSelectedProduct(object)
  }
  return selectedTeamProduct
}

function useTeamSelectedLocationData() {
  const { selectedTeamLocation } = useContext(ScrollContext)
  return selectedTeamLocation
}

function useSetSelectedTeamLocationData() {
  const { setSelectedTeamLocation } = useContext(ScrollContext)
  function selectedTeamLocation(object: any) {
    setSelectedTeamLocation(object)
  }
  return selectedTeamLocation
}

function useTeamSelectedRoleData() {
  const { selectedTeamRole } = useContext(ScrollContext)
  return selectedTeamRole
}

function useTeamSetSelectedRoleData() {
  const { setSelectedTeamRole } = useContext(ScrollContext)
  function selectedTeamRole(object: any) {
    setSelectedTeamRole(object)
  }
  return selectedTeamRole
}

function useBuyoutsSelectedStatus() {
  const { selectedBuyoutsStatus } = useContext(ScrollContext)
  return selectedBuyoutsStatus
}

function useBuyoutsSetSelectedStatus() {
  const { setSelectedBuyoutsStatus } = useContext(ScrollContext)
  function selectedBuyoutsStatus(object: any) {
    setSelectedBuyoutsStatus(object)
  }
  return selectedBuyoutsStatus
}

function useBuyoutsFundSelect() {
  const { selectedBuyoutsFund } = useContext(ScrollContext)
  return selectedBuyoutsFund
}

function useBuyoutsSetSelectedFund() {
  const { setSelectedBuyoutsFund } = useContext(ScrollContext)
  function selectedBuyoutsFund(object: any) {
    setSelectedBuyoutsFund(object)
  }
  return selectedBuyoutsFund
}

export {
  ScrollProvider,
  useSetScrollPosition,
  useScrollData,
  useSelectedDrawer,
  useSetSelectedDrawer,
  useTeamFilterParametersData,
  useSetTeamFilterParametersData,
  useTeamSelectedTeamProductData,
  useSetSelectedTeamProductData,
  useTeamSelectedLocationData,
  useSetSelectedTeamLocationData,
  useTeamSelectedRoleData,
  useTeamSetSelectedRoleData,
  useBuyoutsSelectedStatus,
  useBuyoutsSetSelectedStatus,
  useBuyoutsFundSelect,
  useBuyoutsSetSelectedFund,
}
