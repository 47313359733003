// extracted by mini-css-extract-plugin
export var nav = "style-module--nav--3DYEG";
export var background = "style-module--background--_j_LH";
export var button = "style-module--button--3-BsJ";
export var marginTop25 = "style-module--margin-top-25--1C8I6";
export var ul = "style-module--ul--2NZ20";
export var span = "style-module--span--2mJIu";
export var iconPlaceholder = "style-module--icon-placeholder--182G2";
export var textPlaceholder = "style-module--text-placeholder--3J7L5";
export var refresh = "style-module--refresh--eGDU_";
export var logo = "style-module--logo--Mkn4c";
export var logoBlack = "style-module--logoBlack--2iMk2";
export var innercontainer = "style-module--innercontainer--Loube";
export var __innerLink = "style-module--__inner-link--1nF8F";