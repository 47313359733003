import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { PageLink } from '../link'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0.33,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export const MenuItem = ({ item, toggle, className, id, opacity, setOpacity, _key, pathName }) => {
  const [hoverState, setHoverState] = useState(false)
  const url =
    item.link.content.main.slug.current === 'home' ? '/' : `/${item.link.content.main.slug.current}`

  const onMouseLeave = () => {
    setOpacity('first')
    setHoverState(false)
  }

  const onMouseEnter = () => {
    setHoverState(true)
  }

  const MobileClick = id => {
    setOpacity(id)
    toggle()
  }

  return (
    <PageLink
      activeClass="null"
      onMouseLeave={() => onMouseLeave()}
      onMouseOver={() => onMouseEnter()}
      style={{
        opacity: opacity === 'first' && !hoverState ? 0.33 : 1,
        textDecoration: 'none',
      }}
      onClick={e => MobileClick(item.link.id)}
      key={_key}
      className={`   blue margin-bottom-1rem __inner-link __hover-mobile`}
      to={url}
    >
      <motion.text
        className="__inner-link neue-pro-light-45-hamburger-text-main text-decoration-none fade-in"
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1 }}
      >
        {item.title}
      </motion.text>
    </PageLink>
  )
}
