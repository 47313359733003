import React from 'react'
import { LogoGreen } from './svgs'
import { PageLink } from 'src/components/link'

import { StandardText } from 'src/components/global/standardText'

export const Footer = ({ menu }: any) => {
  const footerMenu =
    menu &&
    menu.data.allSanityMenus.edges.filter(
      x => x.node.slug.current === 'footer-social-media-links'
    )[0].node
  const infoBLocks = menu && menu.data.allSanitySiteGlobal.edges.find(x => x.node.id)
  return (
    <div className="footer-container padding-box ">
      <div className="__contact-information-container blue">
        <LogoGreen className="--logo" />
        {infoBLocks &&
          infoBLocks.node._rawContent.footerBlocks.map((item, index) => {
            return <InfoBox key={index} data={item} />
          })}
      </div>

      <FooterNavContainer footerMenu={footerMenu} />
    </div>
  )
}

const FooterNavContainer = ({ footerMenu }) => {
  return (
    <div className="x  flex footer-nav-container">
      <ul className=" __footer-nav-items">
        {footerMenu &&
          footerMenu._rawItems.map((item, index) => {
            const { _type, _key } = item
            switch (_type) {
              case 'externalLink':
                return (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`font-65-medium   text-decoration-none  ${
                      index === 0 || index === 1 ? 'green background-green ' : 'blue'
                    } fit-content `}
                    key={_key}
                    href={item.link}
                  >
                    {item.title}{' '}
                  </a>
                )
              case 'internalLink':
                return (
                  <PageLink
                    activeClass="null"
                    key={_key}
                    className={`font-65-medium blue    text-decoration-none  ${
                      index === 0 || index === 1 ? 'green background-green' : ''
                    } fit-content `}
                    to={`/${item.link.content.main.slug.current}`}
                  >
                    {item.title}
                  </PageLink>
                )
              case 'fileAsset':
                return (
                  <a
                    target="_blank"
                    rel="noopener  noreferrer"
                    className={`font-65-medium blue    text-decoration-none  ${
                      index === 0 || index === 1 ? 'green background-green' : ''
                    } fit-content `}
                    key={_key}
                    href={item.file.asset.url}
                  >
                    {item.title}{' '}
                  </a>
                )
            }
          })}
      </ul>
      <span className="">Corsair © 2024</span>
    </div>
  )
}

const InfoBox = ({ data }: any) => {
  const { title, content, _key } = data
  return (
    <div className="__box" key={_key}>
      <h1 className="optima-medium-footer blue">{title}</h1>
      <div className="neue-pro-55-text-links ">
        <StandardText className="neue-pro-55-text-links " data={{ text: content }} />
      </div>
    </div>
  )
}

{
  /* <ul className="footer-nav">
{footerMenu &&
            footerMenu._rawItems.map((item, index) => {
              const { _type, _key } = item;
              switch (_type) {
                case "externalLink":
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`font-65-medium blue   hover-on-text text-decoration-none  ${
                        index === 0 || index === 1 ? "green" : ""
                      } fit-content `}
                      key={_key}
                      href={item.link}
                    >
                      {item.title}{" "}
                    </a>
                  );
                case "internalLink":
                  return (
                    <PageLink
                      activeClass="null"
                      key={_key}
                      className={`font-65-medium blue   hover-on-text text-decoration-none  ${
                        index === 0 || index === 1 ? "green" : ""
                      } fit-content `}
                      to={`/${item.link.content.main.slug.current}`}
                    >
                      {item.title}
                    </PageLink>
                  );
                case "fileAsset":
                  return (
                    <a
                      target="_blank"
                      rel="noopener  noreferrer"
                      className={`font-65-medium blue   hover-on-text text-decoration-none  ${
                        index === 0 || index === 1 ? "green" : ""
                      } fit-content `}
                      key={_key}
                      href={item.file.asset.url}
                    >
                      {item.title}{" "}
                    </a>
                  );
              }
            })}

</ul> */
}
