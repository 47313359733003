import React, { useEffect } from 'react'
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript'
import 'highlight.js/styles/github.css'

const CodeSnippet = ({ code }: { code: [] }) => {
  useEffect(() => {
    hljs.registerLanguage('javascript', javascript)
    document.querySelectorAll('pre code').forEach(block => {
      hljs.highlightBlock(block)
    })
  })
  return (
    <pre>
      fsfsfs
      <code>{code}</code>
    </pre>
  )
}

export const Serializer = {
  marks: {
    strong: (props: { children: any }) => (
      <text className="optima-headline green ">{props.children}</text>
    ),
    tick: props => <span className="tick">{props.children}</span>,
    link: (props, link) => <Link link={props}>{props.children}</Link>,

    bullet: props => (
      <text className="nhaas-prop-56-it">
        block
        {props.children}
      </text>
    ),
    em: props => <text className="nhaas-prop-56-it">{props.children}</text>,

    code: (props: { children: any }) => <CodeSnippet code={props.children} />,
  },
  listItem: (props: { children: any }) => (
    <div className="bullet-serial-component">
      <div className="__circle"></div>
      <div>{props.children}</div>
    </div>
  ),
}

const Link = (props, link) => {
  const { href, genre, blank, _key, underlineText } = props.link.mark
  const isUnderline = underlineText ? 'text-decoration-underline cursor' : 'decoration-none cursor'

  switch (genre) {
    case 'mailto':
      return (
        <a key={_key} style={{ color: 'inherit' }} className={isUnderline} href={'mailto:' + href}>
          {props.children}
        </a>
      )
    case 'href':
      return (
        <a
          key={_key}
          style={{ color: 'inherit' }}
          className={isUnderline}
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          {props.children}
        </a>
      )
    case 'tel':
      return (
        <a key={_key} style={{ color: 'inherit' }} className={isUnderline} href={'tel:' + href}>
          {props.children}
        </a>
      )
    default:
      return (
        <a
          style={{ color: 'inherit' }}
          className="text-decoration-underline"
          href={props.link.mark.href}
        >
          {props.children}
        </a>
      )
  }
}
