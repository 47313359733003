import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { MenuItem } from './menuItem'
import * as styles from './style.module.css'
import { PageLink } from '../link'
import { navigate } from 'gatsby'

import { LogoGreen, XNavy } from '../svgs'

const variants = {
  inital: {
    display: 'none',
  },
  open: {
    display: 'block',
    transition: {
      x: { stiffness: 1000, velocity: -700 },

      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  closed: {
    display: 'none',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
}

export const Navigation = ({ menuItems, toggle, mobileNavMenu, footerMenu, pathName }) => {
  const [opacity, setOpacity] = useState('first')
  return (
    <motion.div>
      <motion.ul className={styles.ul} variants={variants}>
        <div className={styles.innercontainer}>
          <LogoGreen onClick={() => navigate('/')} className={styles.logo} />
          <XNavy onClick={() => toggle()} className={styles.logoBlack} />
        </div>
        {mobileNavMenu &&
          mobileNavMenu.map((item: any) => {
            const { _type, _key } = item
            switch (_type) {
              case 'internalLink':
                return (
                  <InternalLink
                    opacity={opacity}
                    _key={_key}
                    setOpacity={e => setOpacity(e)}
                    toggle={toggle}
                    pathName={pathName}
                    data={item}
                  />
                )
              case 'dropdownLink':
                return (
                  <DropwDownLink
                    opacity={opacity}
                    _key={_key}
                    setOpacity={e => setOpacity(e)}
                    toggle={toggle}
                    data={item}
                    pathName={pathName}
                  />
                )
              default:
                return null
            }
          })}

        <div className="margin-top-4rem __inner-footer-links-nav-mobile  flex column">
          {footerMenu &&
            footerMenu._rawItems.map((item, index) => {
              const { _type, _key } = item
              switch (_type) {
                case 'externalLink':
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`block fade-in  margin-bottom-1rem font-65-medium  blue block  text-decoration-none hover-on-text fit-content ${
                        index === 0 || index === 1 ? 'green' : ''
                      }`}
                      key={_key}
                      href={item.link}
                    >
                      {item.title}{' '}
                    </a>
                  )

                case 'internalLink':
                  return (
                    <PageLink
                      key={_key}
                      className={`block fade-in margin-bottom-1rem font-65-medium  blue block  text-decoration-none hover-on-text fit-content ${
                        index === 0 || index === 1 ? 'green' : ''
                      }`}
                      to={`/${item.link.content.main.slug.current}`}
                    >
                      {item.title}
                    </PageLink>
                  )
                case 'fileAsset':
                  return (
                    <a
                      target="_blank"
                      rel="noopener _custom noreferrer"
                      className={`block fade-in margin-bottom-1rem font-65-medium blue  hover-on-text fit-content text-decoration-none _custom ${
                        index === 0 || index === 1 ? 'green' : ''
                      } `}
                      key={_key}
                      href={item.file.asset.url}
                    >
                      {item.title}{' '}
                    </a>
                  )
                default:
                  return null
              }
            })}
        </div>
      </motion.ul>
    </motion.div>
  )
}

const InternalLink = ({ data, toggle, opacity, setOpacity, pathName, _key }: any) => {
  return (
    <div key={`header-container${_key}`} className=" margin-bottom-2rem">
      <MenuItem
        _key={_key}
        pathName={pathName}
        item={data}
        toggle={toggle}
        opacity={opacity}
        setOpacity={setOpacity}
      />
    </div>
  )
}

const DropwDownLink = ({
  data,
  toggle,
  // setOpacity,
  opacity,
  pathName,
}: any) => {
  const { title, _key, subLinks } = data
  const isOpacity = subLinks.some(x => x.content.main.slug.current === pathName.split('/')[1])
  const [linkOpacity, setLinkOpacity] = useState(false)
  const [linkOpacityDefault, setLinkOpacityDefualt] = useState(false)

  useEffect(() => {
    setLinkOpacityDefualt(true)
  }, [])

  const mouseEnter = () => {
    setLinkOpacityDefualt(false)
    setLinkOpacity(true)
  }

  const mouseLeave = () => {
    setLinkOpacityDefualt(true)
    setLinkOpacity(false)
  }

  return (
    <div
      onTouchMoveCapture={() => console.log(`bang`)}
      onMouseEnter={() => mouseEnter()}
      style={{
        opacity: linkOpacity ? 1 : 0.33,
      }}
      onMouseLeave={() => mouseLeave()}
      key={_key}
      className="  __mobile-dropdown-container"
    >
      <div className=" a-remove-default margin-bottom-2rem  flex space-between __inner-link cursor ">
        <motion.text className=" fade-in neue-pro-light-45-hamburger-text-main  blue mobile-dropdown-btn  ">
          {title}
        </motion.text>
        <div className="mobile-dropdown-content fade-in">
          {subLinks &&
            subLinks.map(item => {
              return (
                <PageLink
                  key={item._key}
                  onClick={() => toggle()}
                  activeClass={`null`}
                  className=" neue-pro-light-45-hamburger-text-main __inner-link margin-bottom-2rem blue text-decortation-none   "
                  to={`/${item.content.main.slug.current}`}
                >
                  {item.content.main.title}
                </PageLink>
              )
            })}
        </div>
      </div>
    </div>
  )
}
