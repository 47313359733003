import '../styles/main.scss'
import React from 'react'

import { Helmet } from 'react-helmet'

import { Header } from 'src/components/header'
import { Footer } from 'src/components/footer'

const Layout = ({
  children,
  siteMetadata,
  location,
  pageContext,
  preview,
}: {
  children: any
  siteMetadata: any
  location: any
  pageContext: any
  preview: any
}) => {
  const { menus } = pageContext
  const isCaseStudyPage = location.pathname.split('/').includes('insight')
  const isTeam = location.pathname.split('/').includes('team')
  return (
    <React.Fragment>
      <Helmet title="Corsair">
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <link
          rel="stylesheet"
          href="https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css"
        />
      </Helmet>
      <div>
        {!isCaseStudyPage && !isTeam ? <Header location={location} menu={menus} /> : null}
        <div className="page-container">
          <div className="page-content">
            {children}
            {!isCaseStudyPage && !isTeam ? <Footer menu={menus && menus} /> : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout
