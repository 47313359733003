import React from 'react'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

export interface StandardTextProps {
  className?: any
  data: {
    text: any[]
  }
}

export const StandardText = ({ data, className }: StandardTextProps) => {
  const { text } = data
  return (
    <div className={`${className} standard-text-section `}>
      <BlockContent blocks={text} serializers={Serializer} />
    </div>
  )
}
